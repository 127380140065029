import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero"
import ProductListing from "../../components/lists/ProductListing"
import InlineBreaker from "../../components/lists/InlineBreaker/InlineBreaker"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const inlineBreakers = useStaticQuery(graphql`{
  allProduktListenStoererHJson(
    filter: {anzeige: {elemMatch: {pfad: {eq: "produkte/schilder"}}}}
  ) {
    edges {
      node {
        button {
          link
          text
        }
        kurzbeschreibung
        title
        icon {
          alt
          ratio
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        anzeige {
          pfad
          positionen
        }
      }
    }
  }
}
`);


    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "Produkte", url: "produkte" },
                { label: "Schilder", url: "schilder" },
            ]}
            breadcrumbColor="light"
            headerColor="dark-blue"
            hasWave={false}
        >
            <Hero
                title="Unsere Schilder"
                subtitle="Die Möglichkeiten für Schilder sind grenzenlos. Allein die verschiedenen Formen ermöglichen es, viele kreative Gedanken umzusetzen. Gerade ungewöhnliche Formen erregen Aufmerksamkeit beim Betrachter. Ihre Botschaft auf dem Schild wird somit viel intensiver wahr genommen. "
                color="dark-blue"
                hasWaveBottom={["mobile"]}
            />

            <ProductListing 
                hasFilters={true} 
                filterByType="schild" 
                inlineBreaker={inlineBreakers.allProduktListenStoererHJson.edges.map(({node}) => (
                    {
                        element: <InlineBreaker 
                            to={node.button.link} 
                            buttonText={node.button.text} 
                            title={node.title} 
                            kurzbeschreibung={node.kurzbeschreibung} 
                            icon={{src: node.icon.src, alt: node.icon.alt, ratio: node.icon.ratio}} 
                        />,
                        colspan: 2,
                        position: node.anzeige.filter(item => item.pfad === "produkte/schilder")[0].positionen,
                    }))
                }
            />
        </Layout>
    )
}
